.articles__list__item {
  height: 160px;
  width: 25rem;
  background-color: #FFFFFF;
  /* box-shadow: 0 8px 64px 0 rgba(6,7,20,0.13); */
  /* box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.082); */
  box-shadow: 0 8px 64px 0 rgba(6,7,20,0.13);
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
}

.articles__list__item h1 {
  font-size: 1.2rem;
  font-family: "Lora";
  margin-bottom: 1rem;
}

.artTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.artTitle img {
  height: 30px;
  width: 30px;
}
.articlesTitle img {
  height: 40px;
  width: 40px;
}

.content {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.277);
}

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn span {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btn span img {
  margin-right: 10px;
}

.btn button img {
  height: 15px;
  width: 20px;
  margin-left: 10px;
}

.btn button {
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  font-family: "Lora";
  font-size: 1.3rem;
  text-decoration: underline;
  cursor: pointer;
  top: .6rem;
  position: relative;
}

@media screen and (max-width: 1300px) {
  .articles__list__item {
    height: auto !important;
    width: 100% !important;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
  }

  .articles__list__item h1 {
    font-size: 1rem !important;
    font-family: "Lora";
    margin-bottom: 0.7rem;
  }

  .content p {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 1300px) {

  .artTitle img {
    height: 20px;
    width: 20px;
  }
  .articlesTitle img {
    height: 30px;
    width: 30px;
  }

  .btn button {
    font-size: 0.9rem;
  }

  .btn button img {
    height: 7px;
    width: 20px;
    margin-left: 10px;
  }

  .articles__list__item h1 {
    font-size: 1rem;
    font-family: "Lora";
    margin-bottom: 0.7rem;
  }

  .content p {
    font-size: 0.8rem;
  }
}

/* ------------------------------card responsiveness here--------------------------------------- */

@media screen and (max-width: 540px) {
  .articles__list__item {
    height: 230px;
    width: 340px;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
  }

  .articles__list__item h1 {
    font-size: 1rem;
    font-family: "Lora";
    margin-bottom: 1rem;
  }

  .artTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .artTitle img {
    height: 25px;
    width: 25px;
  }
  .articlesTitle img {
    height: 40px;
    width: 40px;
  }

  .content {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.277);
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn span {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .btn span img {
    margin-right: 10px;
  }

  .btn button img {
    height: 15px;
    width: 20px;
    margin-left: 10px;
  }

  .btn button {
    outline: none;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    font-family: "Lora";
    font-size: 1.3rem;
    text-decoration: underline;
    cursor: pointer;
  }
}
