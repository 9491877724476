.Bredcrum{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    text-align: center;
}

.Bredcrum p{
    font-size: 1rem;
    cursor: pointer !important;
    white-space: nowrap;
}
.Bredcrum h2{
    font-size: 1.2rem;
    margin-top: 0;
    position: relative;
    top: -.5rem;
    cursor: pointer !important;
   margin: 0 .2rem;
    text-transform: capitalize;
font-family: 'Lora';
}



@media screen and (max-width:540px) {
    .Bredcrum h2{
        font-size: 1rem;
        margin-top: 0;
        position: relative;
        top: -.5rem;
        cursor: pointer !important;
       margin: 0 .2rem;
        text-transform: capitalize;
    font-family: 'Lora';
    font-weight: normal;
   
    }

.Bredcrum{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* white-space: nowrap; */
    flex-wrap: wrap;
    /* margin: 1rem 0; */
    /* margin-top: 2rem; */
}
   
}