.links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 10vh;
  width: 100%;
}

.links span {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.links img {
  margin: 0 10px;
  cursor: pointer;
}

.share {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 540px) {
  .links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 10vh;
    width: 100%;
  }

  .links span {
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    white-space: nowrap;
  }

  .links img {
    margin: 0 5px;
  }
}
